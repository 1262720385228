import { useEffect, useState, useContext } from "react";
import { getFinances } from "../resources/api/dashboard";
import IncomeExpenditure from "./dashboard/IncomeExpenditure";
// import { EventCalendar } from "./events/view-events";
import { AppUserContext } from "../App";
import { Card, Col, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import "./dashboard/dashboard.scss";
// import { getUnapprovedRequisitions } from "../resources/api/requisitions";
import { UCWords, sortFunction } from "../components/resources";
// import { errorAlert } from "../components/toastr";
import moment from "moment";
import cur from "../components/currency";
import { getJobs } from "../resources/api/jobs";
import { getInvoices } from "../resources/api/invoices";
import InvoiceExpenditure from "./dashboard/InvoiceExpenditure";
import { uncompletedJobStatuses, unconfirmedJobStatuses } from "../assets/constants";


const Dashboard = () => {

    const [dash_data, setDashData] = useState({ requisitions: [], upcomingjobs: [], followupjobs: [], invoices: [] })
    const [graph_data, setGraphData] = useState({ invoices: [], payments: [], expenses: [] })
    const { profile } = useContext(AppUserContext);


    /**
     * get the items
     */
    useEffect(() => {

        // getUnapprovedRequisitions()
        //     .then(({ requisitions }) => {
        //         requisitions.sort((a, b) => sortFunction(a, b, "date_added", "asc"));
        //         setDashData(d => ({ ...d, requisitions }))
        //     })
        //     .catch(errorAlert)

        getJobs(true, { status: ["confirmed", "awaiting-client"] })
            .then(({ jobs }) => {
                jobs.sort((a, b) => sortFunction(a, b, "job_date", "asc"));

                let datenow = moment().format("YYYY-MM-DD");

                setDashData(d => ({
                    ...d,
                    upcomingjobs: jobs.filter(j => ((j.end_date >= datenow) && (uncompletedJobStatuses.indexOf(j.status) !== -1))),
                    followupjobs: jobs.filter(j => (unconfirmedJobStatuses.indexOf(j.status) !== -1)),
                }))
            })

        getInvoices(true, { status: ["overdue"] })
            .then(({ invoices }) => {
                invoices.sort((a, b) => sortFunction(a, b, "due_date", "asc"));
                setDashData(d => ({
                    ...d,
                    invoices
                }))
            })

        getFinances()
            .then(({ data: { invoices, expenses, payments } }) => {
                setGraphData({ invoices, expenses, payments });
            })

    }, [])

    // useEffect(() => {

    //     if (profile.permission_level === 1) {
    //         Promise.all([
    //             getIncomes(),
    //             getExpenses()
    //         ]).then(([incomes, expenses]) => {
    //             setDashData({ incomes: incomes.data.incomes, expenses: expenses.data.expenses })
    //         })
    //     }


    return (
        <div className="dashboard">
            <h1 className="fw-bold">
                Hello {profile.display_name} <i className="far fa-hand-peace ms-3" />
            </h1>
            <p className="lead">
                Let's take a glance at things right now!
            </p>

            <h3 className="mt-4 mt-md-5 underlined fw-bold">
                A few things for you to look at
            </h3>



            <Row className="upcoming">
                <Col xl={6} md={6} className="my-3">
                    <Card>
                        <Card.Header as="h5" className="py-3 d-flex justify-content-between">
                            <span>
                                Upcoming Jobs
                            </span>
                            <Link to={`/app/jobs/new`}>
                                <i className="fas fa-plus-circle" />
                            </Link>
                        </Card.Header>

                        <ListGroup variant="flush">

                            {dash_data.upcomingjobs.slice(0, 5).map(r => (
                                <ListGroupItem key={r.id}>
                                    <div className="d-flex justify-content-between">
                                        <span className="fw-bold">
                                            {r.client}
                                        </span>

                                        <span className="small">{moment(r.job_date).format("DD MMM YYYY")} | {r.status}</span>
                                    </div>

                                    <Link className="h5 mt-2 mb-1 stretched-link" to={`/app/jobs/${r.id}`}>
                                        {r.title} - {r.location}
                                    </Link>

                                    <div>
                                        {r.job_no}
                                    </div>
                                </ListGroupItem>
                            ))}

                            {(dash_data.upcomingjobs.length === 0) &&
                                <ListGroupItem>
                                    No upcoming jobs
                                </ListGroupItem>
                            }

                            <ListGroupItem className="text-end">
                                <Link to={`/app/jobs?view=confirmed`}>All Confirmed jobs</Link>
                            </ListGroupItem>

                        </ListGroup>
                    </Card>
                </Col>
                <Col xl={6} md={6} className="my-3">
                    <Card>
                        <Card.Header as="h5" className="py-3 d-flex justify-content-between">
                            <span>
                                Follow Up Jobs
                            </span>
                            <Link to={`/app/jobs/new`}>
                                <i className="fas fa-plus-circle" />
                            </Link>
                        </Card.Header>
                        <ListGroup variant="flush">

                            {dash_data.followupjobs.slice(0, 5).map(r => (
                                <ListGroupItem key={r.id}>
                                    <div className="d-flex justify-content-between">
                                        <span className="fw-bold">
                                            {r.client}
                                        </span>

                                        <span className="small">{moment(r.job_date).format("DD MMM YYYY")} | {UCWords(r.status.replace(/-/g," "))}</span>
                                    </div>

                                    <Link className="h5 mt-2 mb-1 stretched-link" to={`/app/jobs/${r.id}`}>
                                        {r.title} - {r.location}
                                    </Link>

                                    <div>
                                        {r.job_no}
                                    </div>

                                </ListGroupItem>
                            ))}

                            {(dash_data.followupjobs.length === 0) &&
                                <ListGroupItem>
                                    No follow up jobs
                                </ListGroupItem>
                            }

                            <ListGroupItem className="text-end">
                                <Link to={`/app/jobs?view=unconfirmed`}>All Follow Up jobs</Link>
                            </ListGroupItem>
                        </ListGroup>
                    </Card>
                </Col>
                {/* <Col xl={6} md={6} className="my-3">
                    <Card>
                        <Card.Header as="h5" className="py-3 d-flex justify-content-between">
                            <span>
                                Pending Requisitions
                            </span>
                            <span>
                                <Link to={`/app/requisitions/process`} className="me-2">
                                    <i className="fas fa-check-circle" />
                                </Link><Link to={`/app/requisitions/new`}>
                                    <i className="fas fa-plus-circle" />
                                </Link>
                            </span>
                        </Card.Header>
                        <ListGroup variant="flush">

                            {dash_data.requisitions.slice(0, 5).map(r => (
                                <ListGroupItem key={r.id}>
                                    <div className="d-flex justify-content-between">
                                        <span className="fw-bold">
                                            {r.reference} | {r.display_name}
                                        </span>

                                        <span>{moment(r.requisition_date).format("DD MMM YYYY")}</span>
                                    </div>

                                    <Link className="h5 mt-2 mb-1 stretched-link" to={`/app/requisitions/${r.id}`}>
                                        {cur(r.total_amount).format()}
                                    </Link>
                                    <div className="form-text">{r.description}</div>

                                </ListGroupItem>
                            ))}

                            {(dash_data.requisitions.length === 0) &&
                                <ListGroupItem>
                                    All requisitions have been approved.
                                </ListGroupItem>
                            }

                            <ListGroupItem className="text-end">
                                <Link to={`/app/requisitions/new`} className="mx-1">Create Requisition</Link>|
                                <Link to={`/app/requisitions`} className="mx-1">All Requisitions</Link>
                            </ListGroupItem>
                        </ListGroup>
                    </Card>
                </Col> */}
                <Col xl={6} md={6} className="my-3">
                    <Card>
                        <Card.Header as="h5" className="py-3 d-flex justify-content-between">
                            <span>
                                Overdue Invoices
                            </span>
                            <Link to={`/app/invoices/new`}>
                                <i className="fas fa-plus-circle" />
                            </Link>
                        </Card.Header>
                        <ListGroup variant="flush">

                            {dash_data.invoices.slice(0, 5).map(r => (
                                <ListGroupItem key={r.id}>
                                    <div className="d-flex justify-content-between">
                                        <span className="fw-bold">
                                            {r.invoice_no} | ({cur(r.due_amount).format()})
                                        </span>

                                        <span>{moment(r.invoice_date).format("DD MMM YYYY")}</span>
                                    </div>

                                    <Link className="h5 mt-2 mb-1 stretched-link" to={`/app/invoices/${r.id}`}>
                                        {cur(r.balance).format()}
                                    </Link>
                                    <div>{r.client}</div>
                                    <div className="form-text">{r.subject}</div>

                                </ListGroupItem>
                            ))}

                            {(dash_data.upcomingjobs.length === 0) &&
                                <ListGroupItem>
                                    No overdue invoices
                                </ListGroupItem>
                            }
                            <ListGroupItem className="text-end">
                                <Link to={`/app/invoices`} className="mx-1">All Invoices</Link>|
                                <Link to={`/app/invoices?view=overdue`} className="mx-1">Overdue Invoices</Link>
                            </ListGroupItem>
                        </ListGroup>
                    </Card>

                </Col>
            </Row>

            <h3 className="mt-4 mt-md-5 underlined fw-bold">
                General performance in graphics
            </h3>

            <div className="max-1200 my-5">
                <IncomeExpenditure incomes={graph_data.payments} expenses={graph_data.expenses} />
                <InvoiceExpenditure incomes={graph_data.invoices} expenses={graph_data.expenses} />
            </div>
        </div >
    )

}

export default Dashboard;