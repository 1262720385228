import { Routes, Route } from 'react-router-dom';
import PricelistItemDetails from './pricelist-details';
import { NewPricelistItem } from './pricelist-manipulation';
import ViewPriceList from './view-pricelist';
import { AppUserContext } from "../../App";
import { ErrorLoading } from '../../components/loading';
import { useContext } from 'react';

const PriceList = () => {

    const { profile } = useContext(AppUserContext);

    if (profile.permission_level > 3) {
        return <ErrorLoading>You do not have the permission to access pricelist.</ErrorLoading>
    }

    return (
        <Routes>
            <Route path="/" element={<ViewPriceList />} />
            <Route path="/new" element={<NewPricelistItem />} />
            <Route path="/:itemid/*" element={<PricelistItemDetails />} />
        </Routes>
    )
}

export default PriceList;