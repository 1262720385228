import Menu from "./Menu";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Dashboard from "./Dashboard";
import Clients from "./clients/clients";
import Payments from "./payments/payments";
import Users from "./users/users";
import { useContext, useEffect } from "react";
import { AppUserContext } from "../App";
import Profile from "./profile/profile";
// import Expenses from "./expenses/expenses";
import Invoices from "./invoices/invoices";
import Jobs from "./jobs/jobs";
// import Inventory from "./inventory/inventory";
import PriceList from "./pricelist/pricelist";
import Accounts from "./accounts/accounts";
// import Requisitions from "./requisitions/requisitions";
import Files from "./files/files";
// import Payroll from "./payroll/payroll";





/**
 * Displays Safaris Management App.
 */
const App = ({ logoutUser }) => {
    const { isLoggedIn } = useContext(AppUserContext);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!isLoggedIn) {
            const path = encodeURIComponent(location.pathname);
            navigate(`/?redirect=${path}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, navigate])


    return (
        <>
            <Menu logoutUser={logoutUser} />
            <div className="content-wrapper px-1 px-md-3">
                <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="clients/*" element={<Clients />} />
                    <Route path="jobs/*" element={<Jobs />} />
                    <Route path="invoices/*" element={<Invoices />} />
                    <Route path="payments/*" element={<Payments />} />
                    {/* <Route path="requisitions/*" element={<Requisitions />} /> */}
                    {/* <Route path="expenses/*" element={<Expenses />} /> */}
                    <Route path="accounts/*" element={<Accounts />} />
                    {/* <Route path="payroll/*" element={<Payroll />} /> */}
                    {/* <Route path="inventory/*" element={<Inventory />} /> */}
                    <Route path="pricelist/*" element={<PriceList />} />
                    <Route path="files/*" element={<Files />} />
                    <Route path="users/*" element={<Users />} />
                    <Route path="me/*" element={<Profile />} />
                </Routes>
            </div>
        </>
    )
}

export default App;