import { APIURL, deleleData, getData, postData } from "../fetch";


/**
 * @typedef {{
 * lpo_no: string
 * lpo_date: string
 * }} LPOObject
 */

/**
 * @typedef {{
 *  id: string
 *  file_id: string, 
 *  job_id: string, 
 *  category: string, 
 *  file_title: string
 *  file_group: string
 *  file_slug: string
 *  display_name: string
 *  added_by: string
 * }} JobDocObject
 */

/**
 * 
 * @typedef {{
 *  id: string 
 *  type: "individual"|"corporate"
 *  reference: string
 *  title: string
 *  job_no: string
 *  client_id: string 
 *  client: string|import("./clients").ClientObject
 *  client_contact_id: string
 *  client_contact: string|import("../../app/users/view-users").UserObject
 *  lead_contact_id: string
 *  lead_contact: string|import("../../app/users/view-users").UserObject
 *  job_date: string 
 *  end_date: string 
 *  start_time: string 
 *  end_time: string 
 *  location: string 
 *  category: string 
 *  total_amount: number
 *  payment_amount: number
 *  balance: number
 *  lpo_file_id: string
 *  confirmation_file_id: string 
 *  depositRequired: boolean
 *  hasInvoice: bool
 *  fullPaymentBeforeDelivery: boolean
 *  min_deposit: number
 *  status: string
 *  lpo_no: string 
 *  lpo_date: string
 *  added_by: string 
 *  updated_by: string 
 *  date_added: string 
 *  date_updated: string 
 *  notes: NoteObject[]
 *  expenses: import("./expenses").ExpenseObject[]
 *  invoice: import("./invoices").InvoiceObject,
 *  job_dates: DateObject[]
 *  documents: JobDocObject[]
 * }} JobObject
 */


/**
 * 
 * @typedef {{
 *  id: string 
 *  job_id: string
 *  content: string 
 *  added_by: string 
 *  date_added: string 
 *  display_name: string
 * }} NoteObject
 */


/**
 * 
 * @typedef {{
 *  id: string 
 *  job_id: string
 *  start: string 
 *  end: string 
 *  date_added: string 
 *  added_by: string
 *  client: string
 *  job: string
 * }} DateObject
 */


/**
 * @typedef {{
 *  id: string 
 *  job_id: string
 *  pricelist_item_id: string
 *  title: string
 *  days: number
 *  quantity: number
 *  comments: string
 *  added_by: string
 * }} PackingListObject
 */





/**
 * 
 * @param {JobObject} data 
 * @returns {Promise<{code: number, message: string, job: JobObject}>}
 */
const createJob = data => postData({ url: `${APIURL}/jobs`, data })

/**
 * 
 * @param {EventObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, job: JobObject}>}
 */
const updateJob = (data, id) => postData({ url: `${APIURL}/jobs/${id}`, data })
/**
 * 
 * @param {string} invoice_id
 * @param {string} id
 * @returns {Promise<{code: number, message: strint}>}
 */
const attachInvoice = (invoice_id, id) => postData({ url: `${APIURL}/jobs/${id}/attach-invoice`, data: { invoice_id } })

/**
 * 
 * @param {boolean} getAll If to get the expanded list  
 * @returns {Promise<{code: number, message: string, jobs: JobObject[], job_dates: DateObject[]}>}
 */
const getJobs = (getAll = false, conditions = null) => {
    const obj = { url: `${APIURL}/jobs`, data: {} }
    if (getAll) obj.data.expanded = 1;
    if (conditions) obj.data = { ...obj.data, ...conditions };
    return getData(obj);

}


/**
 * 
 * @param {string} id 
 * @param {string[]|null} includes 
 * @returns  {Promise<{code: number, message: string, job: JobObject}>}
 */
const getJob = (id, includes = null) => {

    const obj = { url: `${APIURL}/jobs/${id}` };
    if (includes) obj.data = { include: includes.join(",") };
    return getData(obj);
}



/**
 * Delete an event
 * @param {string} id ID of the event
 * @returns  {Promise<{code: number, message: string}>}
 */
const deleteJob = id => deleleData({ url: `${APIURL}/jobs/${id}` });

const changeJobStatus = (id, status) => postData({ url: `${APIURL}/jobs/${id}/status/${status}` });

/**
 * Create notes
 * @param {string} id 
 * @param {string} content of the note 
 * @returns  {Promise<{code: number, message: string, note: NoteObject}>}
 */
const createNote = (id, content) => {
    const obj = { url: `${APIURL}/notes`, data: { content, job_id: id } };
    return postData(obj);
}


/**
 * Delete a note
 * @param {string} id ID of the note
 * @returns  {Promise<{code: number, message: string}>}
 */
const deleteNote = id => deleleData({ url: `${APIURL}/notes/${id}` });



/**
 * Create notes
 * @param {string} id 
 * @param {DateObject} data of the note 
 * @returns  {Promise<{code: number, message: string, job_date: DateObject}>}
 */
const createDates = (id, data) => {

    const obj = { url: `${APIURL}/jobs/${id}/dates`, data };
    return postData(obj);
}

/**
 * Create notes
 * @param {string} id 
 * @param {DateObject} data of the note 
 * @returns  {Promise<{code: number, message: string, job_date: DateObject}>}
 */
const updateDate = (data, job_id, date_id) => {

    const obj = { url: `${APIURL}/jobs/${job_id}/dates/${date_id}`, data };
    return postData(obj);
}

/**
 * Delete a note
 * @param {string} id ID of the note
 * @returns  {Promise<{code: number, message: string}>}
 */
const deleteDate = (id, dateid) => deleleData({ url: `${APIURL}/jobs/${id}/dates/${dateid}` });



/**
 * 
 * @param {string} id ID of the job
 * @returns  {Promise<{code: number, message: string, items: PackingListObject[]}>}
 */
const getPackingList = id => getData({ url: `${APIURL}/jobs/${id}/packing-list` })

/**
 * 
 * @param {PackingListObject} data 
 * @returns  {Promise<{code: number, message: string, item: PackingListObject}>}
 */
const createPackingListItem = data => postData({ url: `${APIURL}/packing-list`, data })


/**
 * 
 * @param {PackingListObject} data 
 * @returns  {Promise<{code: number, message: string, item: PackingListObject}>}
 */
const updatePackingListItem = (data, id) => postData({ url: `${APIURL}/packing-list/${id}`, data })


/**
 * 
 * @param {PackingListObject} data 
 * @returns  {Promise<{code: number, message: string}>}
 */
const deletePackingListItem = id => deleleData({ url: `${APIURL}/packing-list/${id}` })


/**
 * 
 * @param {string} id 
 * @param {JobDocObject} data
 * @returns  {Promise<{code: number, message: string, document: JobDocObject}>}
 */

const uploadDocument = (id, data) => postData({ url: `${APIURL}/jobs/${id}/documents`, data })

const deleteDocument = (jobid, id) => deleleData({ url: `${APIURL}/jobs/${jobid}/documents/${id}` });


/**
 * 
 * @param {string} start_date 
 * @param {string} end_date 
 * @returns  {Promise<{code: number, message: string, packing_list: {job_date: string, job_no: string, item: string, location: string}[]}>}
 */
const getJobsPackingList = (start_date, end_date) => {

    const obj = { url: `${APIURL}/jobs/packing-list`, data: { start_date, end_date } };

    return getData(obj);
}



export {
    createJob,
    deleteJob,
    getJobs,
    getJob,
    updateJob,
    createNote,
    deleteNote,
    createDates,
    deleteDate,
    updateDate,
    attachInvoice,
    changeJobStatus,
    uploadDocument,
    deleteDocument,
    getPackingList,
    createPackingListItem,
    updatePackingListItem,
    deletePackingListItem,
    getJobsPackingList
}