import Nav from '../../components/breadcrumb';
// import PageHeader, { Title } from './../../components/pageheader';

import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { ErrorLoading, PageLoading } from '../../components/loading';
import InvoiceForm from './invoice-form';
import { getInvoice } from '../../resources/api/invoices';
import { errorAlert } from '../../components/toastr';
import { getJob } from '../../resources/api/jobs';
import { DefaultNotes, DefaultTerms } from '../../assets/constants';
import { sortFunction } from '../../components/resources';
import { useContext } from 'react';
import { AppUserContext } from '../../App';

const invoiceObject = {
    id: "", job_id: "", invoice_date: "", due_date: "", subject: "", isTaxInclusive: false, items: [],
    discount_type: "amount", discount_value: "0", notes: DefaultNotes, tcs: DefaultTerms, client_id: "", tax_percent: "0",
    management_fee_type: "amount", management_fee_value: "0", currency: "UGX", exchange_rate: 1
}

const NewInvoice = () => {

    const nav_items = [
        { title: 'Invoices', href: '/app/invoices' },
        { title: 'New Invoice' }
    ];

    const [searchParams] = useSearchParams();
    const [details, setDetails] = useState(invoiceObject);
    const [loaded, setLoaded] = useState(false);

    const navigate = useNavigate();


    useEffect(() => {

        let jobid = searchParams.get('job'),
            cloneid = searchParams.get('clone'),
            clientid = searchParams.get('client'),
            _details = {};

        if (cloneid) {

            const parts = cloneid.split("."),
                includes = ['items'];

            if (parts[1]) includes.push('versions');

            getInvoice(parts[0], includes)
                .then(({ invoice }) => {

                    invoice.items.sort((a, b) => sortFunction(a, b, "position", "asc"));

                    Object.keys(invoiceObject).map(key => (_details[key] = invoice[key]));
                    _details = { ..._details, client_id: "", job_id: "", invoice_date: "", due_date: "" };

                    if (parts[1]) {
                        let version = parseInt(parts[1]);
                        _details.items = invoice.versions.find(v => (v.version === version)).items;
                    }

                    setDetails(_details);
                })
                .catch(e => errorAlert(`Unable to get the invoice to clone. ${e}`))
                .finally(() => setLoaded(true));

        } else if (jobid) {
            getJob(jobid)
                .then(({ job }) => {
                    _details = { ...invoiceObject, client_id: job.client_id, job_id: job.id };
                    setDetails(_details);
                })
                .catch(e => errorAlert(`Unable to get the job details. ${e}`))
                .finally(() => setLoaded(true));

            setDetails(_details);
        } else if (clientid) {
            setDetails(d => ({ ...d, client_id: clientid }));
            setLoaded(true);
        } else {
            setLoaded(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmit = (details) => {
        navigate(`/app/invoices/${details.id}`);
    }


    if (!loaded) return <PageLoading />;

    return (
        <>
            <Nav items={nav_items} />

            <div className="max-800 p-2">
                <InvoiceForm
                    details={details}
                    onSubmit={handleSubmit}
                    action="create"
                />
            </div>
        </>
    )

}

/**
 * 
 * @param {Object} props
 * @param {import('../../resources/api/invoices').InvoiceObject} props.details 
 * @param {React.Dispatch<React.SetStateAction<import('../../resources/api/invoices').InvoiceObject>>} props.setDetails 
 */
const EditInvoice = ({ details, setDetails }) => {

    const nav_items = [
        { title: 'Invoices', href: '/app/invoices' },
        { title: details.reference, href: `/app/invoices/${details.id}` },
        { title: 'Edit Details' }
    ];


    const navigate = useNavigate();
    const { profile } = useContext(AppUserContext);


    const handleSubmit = (details) => {
        details.items?.sort((a, b) => sortFunction(a, b, "position", "asc"));

        setDetails(d => ({ ...d, ...details }));
        navigate(`/app/invoices/${details.id}`);
    }



    if (profile.permission_level > 2) {
        return <ErrorLoading>You do not have the permission to edit invoices.</ErrorLoading>
    }


    if ((profile.id !== details.added_by) && (profile.permission_level !== 1))
        return <ErrorLoading>Only the person who input the invoice can edit it.</ErrorLoading>


    return (
        <>
            <Nav items={nav_items} />

            {/* <PageHeader maxWidth="800">
                <Title>{details.payment_no}</Title>
            </PageHeader> */}

            <div className="max-800 p-2">
                <InvoiceForm
                    details={details}
                    onSubmit={handleSubmit}
                    action="edit"
                />
            </div>
        </>
    )

}


export { NewInvoice, EditInvoice };