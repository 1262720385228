import $ from 'jquery';

/**
 * generates a random unique ID. 
 * Not totally unique
 */
const generateID = () => {
    return '_' + Math.random().toString(36).substr(2, 9);
}

/**
 * Loads a style sheet with src given 
 * then returns an ID for the stylesheet
 * @param {string} src 
 * @returns id for stylesheet
 */
export const loadStyleSheet = src => {
    const id = generateID(),
        link = document.createElement('link');

    link.id = id;
    link.rel = 'stylesheet';
    link.href = src;

    document.head.appendChild(link);

    return id;
}

/**
 * 
 * @param {string} string 
 */
export const UCWords = string => string?.split(' ').map(s => s[0]?.toUpperCase() + s.slice(1)).join(' ');

/**
 * options for overriding toastr notifications.
 */
export const toastrOptions = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": false,
    "progressBar": true,
    "positionClass": "toast-top-right",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "600",
    "hideDuration": "1000",
    "timeOut": "5500",
    "extendedTimeOut": "2000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
}

/**
 * 
 * @param {{[any:string]: any}} jqXHR 
 */
const getError = (jqXHR) => {
    var contentType = jqXHR.getResponseHeader("Content-Type"),
        content = jqXHR.responseText || jqXHR.statusText,
        r;

    if (!content) {
        return 'An unknown error occured.';
    }

    if (contentType === 'application/json') {
        try {
            r = JSON.parse(content);
        } catch (e) {
            r = { message: content };
        }

        return r.message;

    } else {

        return content;
    }
}

const getDistanceFromLatLonInKm = (place1, place2) => {
    let lat1 = parseFloat(place1.lat),
        lat2 = parseFloat(place2.lat),
        lon1 = parseFloat(place1.lon),
        lon2 = parseFloat(place2.lon);

    const R = 6371, // Radius of the earth in km
        dLat = deg2rad(lat2 - lat1), // deg2rad below
        dLon = deg2rad(lon2 - lon1),
        a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2),
        c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
}

const deg2rad = deg => deg * (Math.PI / 180);

/**
 * Make a network request 
 * @typedef {{
 * url: string,
 * method: "POST"|"GET"|"DELETE"|"PUT",
 * contentType: string, 
 * dataType: string,
 * data: {[x:string]: any},
 * headers: {[x:string]: any}
 * crossDomain: boolean
 * }} ajaxSettings - Request settings.
 */


/**
 * @param {ajaxSettings} d 
 */
export const fetchData = (d) => {

    let { method, dataType, contentType, ...settings } = d;
    method = method ? (['PUT', 'POST', 'GET', 'DELETE'].indexOf(method) === -1 ? 'GET' : method) : 'GET';
    dataType = dataType || 'json';

    if (contentType) {
        settings.contentType = contentType;

        if (
            settings.data &&
            (contentType.indexOf('application/json') !== -1) &&
            (["POST", "PUT"].indexOf(method) !== -1)
        ) settings.data = JSON.stringify(settings.data);
    }


    return new Promise((resolve, reject) => {

        // handle some errors on type and return errorType, errorMessage, errorFriendlyDetails

        $.ajax({
            method: method,
            dataType,
            ...settings
        })
            .done(({ code, ...others }) => {
                if (code !== 0) {
                    return reject(others.message);
                }

                return resolve(others);
            })
            .fail(jq => reject(getError(jq)));
    })
}


/** @param {ajaxSettings} d */
export const postData = (d) => fetchData({ ...d, method: "POST" });

/** @param {ajaxSettings} d */
export const deleleData = d => fetchData({ ...d, method: "DELETE" });

/**
 * Unloads stylesheet with given ID.
 * @param {string} id 
 */
export const unLoadStyleSheet = id => {
    document.getElementById(id).remove();
}

/**
 * 
 * @param {Object} a 
 * @param {Object} b 
 * @param {string} field 
 * @param {"asc"|"desc"} order 
 */
export const sortFunction = (a, b, field, order = "asc") => {

    if (order === "desc" || order === "dsc") {
        return (b[field] < a[field] ? -1 : (
            b[field] > a[field] ? 1 : 0
        ))
    }

    return (a[field] < b[field] ? -1 : (
        a[field] > b[field] ? 1 : 0
    ))

}

/**
 * 
 * @param {string} email 
 * @returns 
 */
const validateEmail = (email) => {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

/**
 * 
 * @param {{}[]} array 
 * @param {string} category 
 * @returns {{[any:string]: {}[]}}
 */
const groupBy = (array, category) => {

    return array.reduce((group, array_item) => {

        const _category = array_item[category];

        group[_category] = group[_category] ?? [];
        group[_category].push(array_item);

        return group;
    }, {});
}

const hexToRGB = (hex, alpha = null) => {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
}

export { getError, getDistanceFromLatLonInKm, deg2rad, validateEmail, groupBy, hexToRGB };

