import { Form, Row, Col } from 'react-bootstrap';
import { useState } from 'react';
import { errorAlert, successAlert } from './../../components/toastr';
import { SubmitButton, CancelButton } from './../../components/btns';
import { createClient, updateClient } from '../../resources/api/clients';
import { useNavigate } from 'react-router-dom';
import { ContactPersons } from './contact-person-form';

const { Control, Control: { Feedback }, Label } = Form;

/**
 * handle page for creating trip
 * @param {Object} props
 * @param {"edit"|"create"} props.action
 * @param {import('../../resources/api/clients').ClientObject} props.details
 * @param {(details: import('../../resources/api/clients').ClientObject) => void} props.onSubmit
 */
const ClientForm = ({ details: clientObject, action, onSubmit }) => {


    const [details, setDetails] = useState(clientObject);
    const [items, setItems] = useState([{ other_names: "", last_name: "", email: "", telephone: "" }])

    const [validated, setValidated] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);

    const navigate = useNavigate();

    /**
     * handle the overall submitting of the form
     * @param {React.FormEvent} e
     */
    const handleSubmit = e => {
        const form = e.currentTarget;

        e.preventDefault();

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("You have errors in your form. These have been highlighted for you.", "Form Errors");
            return;
        }

        setValidated(false);
        setSubmitting(true);
        
        const promise = (action === "edit") ? updateClient(details, details.id) : createClient({ ...details, contact_persons: items });


        promise
            .then(({ client, message }) => {
                successAlert(message);
                onSubmit(client);
            })
            .catch(e => {
                errorAlert(e);
                setSubmitting(false);
            })
    }

    return (
        <Form className="max-800" validated={validated} noValidate onSubmit={handleSubmit}>
            {/* <h4 className="form-section-label">Details</h4> */}
            <Row>
                <Col sm={12} className="my-1">
                    <Label className="form-field-title">Title</Label>
                    <Control
                        value={details.title}
                        onChange={e => setDetails({ ...details, title: e.currentTarget.value })}
                        required={true}
                    />
                    <Feedback type="invalid">
                        The title is required.
                    </Feedback>
                    <Form.Text muted>
                        This can be the client's full name or company/organisation title.
                    </Form.Text>
                </Col>
            </Row>

            <Row>
                <Col sm={12} className="my-1">
                    <Label className="form-field-title">Address</Label>
                    <Control
                        value={details.address}
                        onChange={e => setDetails({ ...details, address: e.currentTarget.value })}
                        required
                    />
                    <Feedback type="invalid">
                        Client address is required
                    </Feedback>
                </Col>
            </Row>


            {(action === "create") &&
                <>
                    <h4 className="form-section-label mt-5">Contact Persons</h4>
                    <ContactPersons
                        contactpersons={{ items, setItems }}
                    />
                </>}

            <Row>
                <Col className="mt-4 mb-3 text-end">
                    <SubmitButton isSubmitting={isSubmitting} type="submit">
                        {action === "edit" ? "Update Client" : "Create Client"}
                    </SubmitButton>
                    <CancelButton isSubmitting={isSubmitting} onClick={() => navigate(-1)}>
                        Cancel
                    </CancelButton>
                </Col>
            </Row>
        </Form>
    )
}


export default ClientForm;