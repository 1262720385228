import { Row, Col, Form, Button, InputGroup as Group, Table } from 'react-bootstrap';
import { useContext, useEffect, useMemo, useState } from 'react';
import { error as errorAlert, info as infoAlert } from './toastr';
import currency from './currency';
import { FormSelect, InputGroup } from 'react-bootstrap';
import { useCallback } from 'react';
import { UCWords, sortFunction } from './resources';
import { allExpenseCategories, expenseCategories, taxes } from '../assets/constants';
import { Link } from 'react-router-dom';
import cur from './currency';
import { AppUserContext } from '../App';
// import cur from './currency';

export const NewInvoiceItem = {
    title: '',
    description: '',
    quantity: '',
    unit_price: '',
    tax_percent: '',
    expense_id: null,
    pricelist_item_id: '',
    total_amount: 0,
    days: 1,
    isNew: true,
    isHeading: false
}

/**
 * @param {Object} props
 * @param {{
 *      items: import('../resources/api/invoices').InvoiceItemObject[], 
 *      setItems: React.Dispatch<React.SetStateAction<import('../resources/api/invoices').InvoiceItemObject[]>>
 * }} props.invoiceitems
 * @param {{
 *     deleted_items: string[], 
 *     setDeletedItems: React.Dispatch<React.SetStateAction<string[]>>
 * }} props.deleteditems
 * @param {boolean} props.isTaxInclusive
 * @param {number} props.taxPercent
 * @param {React.ReactNode} props.children
 */
const InvoiceItems = ({ invoiceitems, children, isTaxInclusive = true, deleteditems, taxPercent = 0, currency: cur = "UGX" }) => {

    const { items, setItems } = invoiceitems;
    const { setDeletedItems } = deleteditems;

    const style = {
        minWidth: '175px',
        textAlign: 'right',
        display: 'inline-block'
    }

    const totals = useMemo(() => {

        if (items.length === 0) return {
            tax: 0,
            total: 0,
            sub: 0
        };

        let tax, sub, total, _tax = [], _total = [];

        items.forEach(i => {
            if (!(i.unit_price && i.quantity && i.days)) return;

            total = i.quantity * i.unit_price * i.days;

            if (!taxPercent) {
                _total.push(total);
                return;
            }

            if (isTaxInclusive) {
                sub = total / (1 + parseFloat(taxPercent) / 100);
                _tax.push(total - sub);
                _total.push(total);
            } else {
                tax = parseFloat(taxPercent) / 100 * total;
                _tax.push(tax);
                _total.push(tax + total);
            }
        })

        /** @type {number} */
        const __total = _total.reduce((p, t) => p + t, 0);

        /** @type {number} */
        const __tax = _tax.reduce((a, t) => a + t, 0);

        return {
            sub: __total - __tax,
            tax: __tax,
            total: __total
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(items), isTaxInclusive, taxPercent])

    /**
     * add a new all empty invoice item.
     */
    const addItem = () => setItems(items.concat({ ...NewInvoiceItem, position: items.length + 1 }));

    const addHeading = () => setItems(items.concat({ ...NewInvoiceItem, days: 0, quantity: 0, unit_price: 0, isHeading: 1, position: items.length + 1 }));

    /**
     * Update an item in the list
     * @param {number} index 
     * @param {import('../resources/api/invoices').InvoiceItemObject} value 
     */
    const updateItem = (index, value) => {
        let current_items = [...items];

        if (!!value.quantity && !!value.unit_price && !!value.days) {
            value.total_amount = parseFloat(value.unit_price) * parseInt(value.quantity) * parseInt(value.days);
        } else {
            value.total_amount = 0;
        }
        value.hasChanged = true;
        current_items.splice(index, 1, value);
        setItems(current_items);
    }

    /**
     * Deletes an item in poisition index from the list.
     * @param {number} index Index of item in the list
     */
    const deleteItem = index => {
        if (items.length < 2) return errorAlert("You must have at least one invoice item.");

        const _item = { ...items[index] },
            p = _item.position;

        if (_item.id) setDeletedItems(items => items.concat(_item.id));

        setItems(items
            .filter((e, i) => i !== index)
            .map(i => ({
                ...i,
                position: (i.position > p) ? (i.position - 1) : i.position,
                hasChanged: (i.position > p) ? true : (i?.hasChanged || false)
            }))
        );
        infoAlert("Invoice item has been deleted.");
    }

    const onPositionChange = (position, direction = "up") => {

        let _items = [...items],
            delta = (direction === "up") ? -1 : 1,
            idx1 = _items.findIndex(i => i.position === position),
            idx2 = _items.findIndex(i => i.position === (position + delta));

        _items[idx1].position = position + delta;
        _items[idx1].hasChanged = true;
        _items[idx2].position = position;
        _items[idx2].hasChanged = true;

        _items.sort((a, b) => sortFunction(a, b, "position", "asc"));

        setItems(_items);
    }

    const handleUpClick = (position) => onPositionChange(position, "up");
    const handleDownClick = (position) => onPositionChange(position, "down");

    return (
        <>
            <Row className="d-none d-sm-flex">
                <Col sm={4} className="py-0">
                    <Form.Label className="form-field-title">Title</Form.Label>
                </Col>
                <Col sm={1} className="py-0">
                    <Form.Label className="form-field-title">Days</Form.Label>
                </Col>
                <Col sm={1} className="py-0">
                    <Form.Label className="form-field-title">Quantity</Form.Label>
                </Col>
                <Col sm={3} className="py-0">
                    <Form.Label className="form-field-title">Unit Price</Form.Label>
                </Col>
                <Col sm={3} className="py-0">
                    <Form.Label className="form-field-title">Total/Action</Form.Label>
                </Col>
            </Row>
            {items.map((e, i) => <InvoiceItem
                key={i} details={e}
                itemLength={items.length}
                onChange={value => updateItem(i, value)}
                onDelete={() => deleteItem(i)}
                onUpClick={handleUpClick}
                onDownClick={handleDownClick}
            />)}
            <Row>
                <Col sm={12} className="mt-3 mb-2 d-flex flex-column-reverse flex-sm-row justify-content-between align-items-center">
                    <div>
                        <Button variant="secondary" size="sm" className="rounded-pill m-1" onClick={addItem}>
                            <i className="fas fa-plus-circle me-2" />Add Item
                        </Button>
                        <Button variant="secondary" size="sm" className="rounded-pill m-1" onClick={addHeading}>
                            <i className="fas fa-heading me-2" />Add Heading
                        </Button>
                        {children}
                    </div>

                    <div className="text-end">
                        {totals.tax > 0 &&
                            <>
                                Sub Total: {cur} <span style={style} className="font-weight-normal h5 my-0">{currency(totals.sub).format()}</span> <br />
                                Tax: {cur} <span style={style} className="font-weight-normal h5 my-0">{currency(totals.tax).format()}</span> <br />
                            </>
                        }
                        Invoice Total: {cur} <span style={style} className="font-weight-bold h4 my-0">{currency(totals.total).format()}</span>
                    </div>

                </Col>
            </Row>
        </>
    )
}

/**
 * 
 * @param {Object} props
 * @param {import('../resources/api/invoices').InvoiceItemObject} props.details
 * @param {(item: import('../resources/api/invoices').InvoiceItemObject) => void } props.onChange
 * @param {(index: number) => void } props.onDelete
 */
const InvoiceItem = ({ details, onChange, onDelete, itemLength, onUpClick, onDownClick }) => {

    const { Label, Control } = Form;
    const [showDes, setShowDes] = useState(false);

    const { profile } = useContext(AppUserContext);


    return (
        <div className="my-3 pb-1 pb-sm-0 my-sm-1 border-bottom">
            <Row className="label-sm-hide g-1">
                <Col xs={12} sm={details.isHeading ? 9 : 4} className="my-1">
                    <div>
                        <Label className="form-field-title">Title</Label>
                        <Control
                            size="sm"
                            value={details.title}
                            onChange={e => onChange(({ ...details, title: e.currentTarget.value }))}
                            required
                            disabled={!!details.pricelist_item_id}
                        />
                        <Control.Feedback type="invalid">
                            Provide the item title
                        </Control.Feedback>
                    </div>

                    {!details.isHeading &&
                        <>
                            {showDes &&
                                <div className="mt-1">
                                    <Control
                                        size="sm"
                                        value={details.description}
                                        onChange={e => onChange(({ ...details, description: e.currentTarget.value }))}
                                        as="textarea"
                                        rows={1}
                                        onFocus={e => e.currentTarget.rows = 4}
                                        onBlur={e => e.currentTarget.rows = 1}

                                    />
                                </div>}
                            <Button size="sm" className="" variant="link" onClick={() => setShowDes(d => !d)}>
                                {showDes ? "Hide Description" : (details.description === "" ? "Add Description" : "Show Description")}
                            </Button>
                        </>
                    }
                </Col>

                {(!details.isHeading) &&
                    <>
                        <Col xs={3} sm={1} className="my-1">
                            <Label className="form-field-title">Days</Label>
                            <Control
                                size="sm"
                                value={details.days}
                                onChange={e => onChange(({ ...details, days: e.currentTarget.value }))}
                                type="number"
                                required
                                min="1"
                            />
                            <Control.Feedback type="invalid">
                                Quantity must not be less than 1.
                            </Control.Feedback>
                        </Col>
                        <Col xs={3} sm={1} className="my-1">
                            <Label className="form-field-title">Qty</Label>
                            <Control
                                size="sm"
                                value={details.quantity}
                                onChange={e => onChange(({ ...details, quantity: e.currentTarget.value }))}
                                type="number"
                                required
                                min="1"
                            />
                            <Control.Feedback type="invalid">
                                Quantity must not be less than 1.
                            </Control.Feedback>
                        </Col>
                        <Col xs={5} sm={3} className="my-1">
                            <Label className="form-field-title">Unit Price</Label>
                            <Control
                                size="sm"
                                value={details.unit_price}
                                onChange={e => onChange(({ ...details, unit_price: e.currentTarget.value }))}
                                type="number"
                                step="0.01"
                                required
                                min="0.01"
                                disabled={!!details.pricelist_item_id && (profile.permission_level > 2)}
                            />
                            <Control.Feedback type="invalid">
                                Unit price must not be less than 0.01.
                            </Control.Feedback>
                        </Col>
                    </>

                }


                <Col xs={12} sm={3} className="my-1">
                    <Label className="form-field-title">Total</Label>
                    <InputGroup>
                        {!details.isHeading &&
                            <Control
                                size={"sm"}
                                value={cur(details.total_amount, 2).format()}
                                readOnly
                            />
                        }

                        <Button className="text-secondary" variant="link" onClick={() => onUpClick(details.position)} size="sm" disabled={details.position === 1}>
                            <i className="fas fa-arrow-up" />
                        </Button>
                        <Button className="text-secondary" variant="link" onClick={() => onDownClick(details.position)} size="sm" disabled={details.position === itemLength}>
                            <i className="fas fa-arrow-down" />
                        </Button>
                        <Button className="text-danger" variant="link" onClick={onDelete} size="sm">
                            <i className="fas fa-times" />
                        </Button>
                    </InputGroup>
                </Col>
            </Row>
        </div>
    )
}

/**
 * 
 * @param {{notes: string[], setNotes: React.Dispatch<React.SetStateAction<string[]>>}} props 
 */
const InvoiceNotes = ({ notes, setNotes }) => {

    useEffect(() => {
        if (notes.length === 0) setNotes(['']);
    }, [notes.length, setNotes])

    const updateNote = (index, value) => {

        let current_notes = [...notes];
        current_notes[index] = value;
        setNotes(current_notes);
    }

    const deleteNote = index => {
        setNotes(notes.filter((e, i) => i !== index));
        infoAlert("Note Item has been deleted.");
    }

    return (
        <Row className='g-2'>
            <Col sm={12} className="my-1">
                {notes.map((n, i) => <NoteItem note={n} key={i} placeholder="-" onChange={text => updateNote(i, text)} required={notes.length > 1} onDelete={() => deleteNote(i)} />)}
            </Col>
            <Col sm={12} className="my-2">
                <Button variant="secondary" size="sm" className="rounded-pill m-1" onClick={() => setNotes(n => [...n, ''])}>
                    <i className="fas fa-plus-circle me-2" />Add Item
                </Button>
            </Col>
        </Row>
    )
}

/**
 * 
 * @param {{
 * note: string, 
 * required: boolean, 
 * placeholder: string,
 * onChange: (value: string) => void,
 * onDelete: () => void
 * }} param0 
 */
const NoteItem = ({ note, onChange, onDelete, required, placeholder }) => {
    return (
        <InputGroup className="my-2">
            <Form.Control as="textarea" rows={1}
                placeholder={placeholder}
                onFocus={e => e.currentTarget.rows = 4}
                onBlur={e => e.currentTarget.rows = 2}
                value={note}
                onChange={e => onChange(e.currentTarget.value)}
                required={required}
            />
            <Button className="text-danger" variant="link" onClick={onDelete}><i className="fas fa-times" /></Button>
            {
                required &&
                <Form.Control.Feedback type="invalid">
                    This field cannot be left empty. You can delete it instead.
                </Form.Control.Feedback>
            }
        </InputGroup>
    )
}

/**
 * 
 * @param {{TCs: string[], setTCs: React.Dispatch<React.SetStateAction<string[]>>}} param0 
 */
const InvoiceTCs = ({ TCs, setTCs }) => {

    useEffect(() => {
        if (TCs.length === 0) setTCs(['']);
    }, [TCs.length, setTCs])

    const updateTC = (index, value) => {

        let current_tcs = [...TCs];
        current_tcs[index] = value;
        setTCs(current_tcs);
    }


    const deleteTC = index => {
        if (TCs.length < 2) return errorAlert("You should at least one TC item on the page");
        setTCs(TCs.filter((e, i) => i !== index));
        infoAlert("Item has been deleted.");
    }

    return (
        <Form.Row>
            <Col sm={12} className="my-1">
                {TCs.map((n, i) => <NoteItem note={n} key={i} placeholder="Terms and Conditions - Late fees, payment methods, delivery schedule" onChange={text => updateTC(i, text)} required={TCs.length > 1} onDelete={() => deleteTC(i)} />)}
            </Col>
            <Col sm={12} className="my-2">
                <Button variant="secondary" size="sm" className="rounded-pill m-1" onClick={() => setTCs(n => [...n, ''])}>
                    <i className="fas fa-plus-circle mr-2" />Add New T&amp;C Item
                </Button>
            </Col>
        </Form.Row>
    )
}

/**
 * 
 * @param {{
 * management_fee?: number
 * tax?: number
 * total?: number
 * discount?: number
 * due?: number
 * balance?: number
 * }} props 
 */
const DisplayTotals = ({ pretax_amount = 0, tax = 0, total = 0, discount = 0, due = 0, balance = 0, currency: cur = "UGX" }) => {
    const style1 = {
        fontWeight: 'normal',
        width: '220px',
        display: 'inline-block',
        marginTop: 0,
        marginBottom: 0,
        textAlign: 'end'
    }
    return (
        <div className="d-flex flex-column align-items-end my-3">
            {/* <div className="my-1">
                MANAGEMENT FEE ({cur}): <span style={style1} className="h4">{currency(management_fee).format()}</span>
            </div> */}
            <div className="my-1">
                SUB TOTAL ({cur}): <span style={style1} className="h5">{currency(pretax_amount).format()}</span>
            </div>
            <div className="my-1">
                TAX ({cur}): <span style={style1} className="h5">{currency(tax).format()}</span>
            </div>
            <div className="my-1">
                TOTAL ({cur}): <span style={style1} className="h5">{currency(total).format()}</span>
            </div>
            <div className="my-1">
                DISCOUNT ({cur}): <span style={style1} className="h5">{currency(discount).format()}</span>
            </div>
            <div className="my-1">
                DUE ({cur}): <span style={style1} className="h5 font-weight-bold">{currency(due).format()}</span>
            </div>
            {
                balance > 0 &&
                <div className="my-1 text-danger">
                    BALANCE ({cur}): <span style={style1} className="h5 font-weight-bold">{currency(balance).format()}</span>
                </div>
            }
        </div>
    )
}

/**
 * 
 * @param {{
 * items: import('../resources/api/invoices').InvoiceItemObject[]
 * }} param0 
 */
const DisplayInvoiceItems = ({ items }) => {

    let count = 1;

    return (
        <Table responsive style={{ minWidth: '600px' }} hover className="items-table">
            <colgroup>
                <col span="1" style={{ width: "3%" }} />
                <col span="1" style={{ width: "45%", textAlign: 'center' }} />
                <col span="1" style={{ width: "8%" }} />
                <col span="1" style={{ width: "8%" }} />
                <col span="1" style={{ width: "18%" }} />
                <col span="1" style={{ width: "18%" }} />
            </colgroup>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Item</th>
                    <th>Days</th>
                    <th>Qty</th>
                    <th className='text-end'>Unit Price</th>
                    <th className='text-end'>Amount</th>
                </tr>
            </thead>
            <tbody>
                {
                    items.map((e, i) => {
                        if (e.isHeading) {
                            return (

                                <tr key={i}>
                                    <td colSpan={6} className='text-start'>
                                        <h6 className='mb-0 mt-1'>
                                            {e.title}
                                        </h6>
                                    </td>
                                </tr>
                            )
                        } else {
                            return (

                                <tr key={i}>
                                    <td>{count++}</td>
                                    <td>
                                        <span className="d-block font-weight-normal">
                                            {e.title} {e.isFromPriceList && <Link to={`/app/pricelist/${e.pricelist_item_id}`}><i className='fas fa-store text-gray ms-2' /></Link>}
                                        </span>
                                        {e.description && <div className="description">{e.description}</div>}
                                    </td>
                                    <td>{e.days}</td>
                                    <td>{e.quantity}</td>
                                    <td className='text-end'>{currency(e.unit_price).format()}</td>
                                    <td className='text-end'>{currency(e.unit_price * e.quantity).format()}</td>
                                </tr>
                            )
                        }
                    })
                }
            </tbody>
        </Table>
    )
}

/**
 * 
 * @param {{
 * notes: string[]
 * noDataMessage: string
 * }} param0 
 */
const DisplayInvoiceNotes = ({ notes = [], noDataMessage }) => {

    if (notes.length === 0) {
        return <div className="lead text-muted mb-2">{noDataMessage || `There are no items to be displayed.`}</div>
    }

    return (
        <>
            {notes.map((e, i) => <li key={i} dangerouslySetInnerHTML={{ __html: e.replace(/\n/g, "<br />") }} />)}
        </>
    )
}


/**
 * 
 * @param {{
 * payments: {payment_no: string, method: string, payment_date: string, payment_amount: number, amount: number}[]
 * }} props 
 */
const DisplayInvoicePayments = ({ payments }) => {

    return (
        <Table responsive style={{ minWidth: '650px' }} hover>
            <colgroup>
                <col span="1" style={{ width: "3%" }} />
                <col span="1" style={{ width: "23%" }} />
                <col span="1" style={{ width: "15%" }} />
                <col span="1" style={{ width: "21%" }} />
                <col span="1" style={{ width: "19%" }} />
                <col span="1" style={{ width: "19%" }} />
            </colgroup>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Payment No</th>
                    <th>Method</th>
                    <th>Date</th>
                    <th>Total Paid</th>
                    <th>This Invoice</th>
                </tr>
            </thead>
            <tbody>
                {payments.map((e, i) => (
                    <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{e.payment_no}</td>
                        <td>{e.method}</td>
                        <td>22 Dec 2020</td>
                        <td>2,800,000.00</td>
                        <td>1,400,000.00</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}


/**
 * Display input fields for payments. 
 * Also displays a sum of all the payments for this payment item.
 * @param {{
 * paymentitems: {items: string[], setItems: React.Dispatch<React.SetStateAction<string[]>>}
 * children: React.ReactNode
 * }} param0 
 */
const PaymentItems = ({ paymentitems, children }) => {

    const { Row } = Form;
    const { items, setItems } = paymentitems;

    /**
     * UPdate an item in the list
     * @param {number} index 
     * @param {} value 
     */
    const updateItem = (index, value) => {

        // console.log(value);
        let current_items = [...items];
        current_items.splice(index, 1, value);
        setItems(current_items);
    }

    const deleteItem = index => {
        setItems(items.filter((e, i) => i !== index));
    }


    const sub_total = items.reduce((p, c) => c.amount ? p + parseFloat(c.amount) : p, 0);

    if (items.length === 0) {
        return (
            <div className="my-2 lead text-muted">
                No Items added yet. To add a payment item, add the invoices from the Invoices Dropdown field above.
            </div>
        )
    }

    return (
        <>
            <Row className="d-none d-sm-flex">
                <Col sm={4} className="py-0">
                    <Form.Label className="form-field-title">Invoice No</Form.Label>
                </Col>
                <Col sm={4} className="py-0">
                    <Form.Label className="form-field-title">Balance</Form.Label>
                </Col>
                <Col sm={4} className="py-0">
                    <Form.Label className="form-field-title">Payment Amount</Form.Label>
                </Col>
            </Row>
            {items.map((e, i) => <PaymentItem key={i} details={e} onChange={value => updateItem(i, value)} onDelete={() => deleteItem(i)} />)}
            <Row>
                <Col sm={12} className="mt-3 mb-2 d-flex flex-column-reverse flex-sm-row justify-content-between align-items-center">
                    <div>
                        {children}
                    </div>

                    <div>
                        Total Payment: UGX <span className="font-weight-bold h4 my-0">{currency(sub_total).format()}</span>
                    </div>
                </Col>
            </Row>
        </>
    )
}


/**
 * 
 * @param {{
 * details: {
 * invoice_id: string,
 * invoice_no: string,
 * balance: number,
 * amount: number
 * },
 * onChange: ({invoice_id: string, amount: number}) => void,
 * onDelete: (invoice_id: string) => void
 * }} param0 
 */
const PaymentItem = ({ details, onChange, onDelete }) => {

    const { Row, Label, Control } = Form;

    return (
        <Row className="my-2 my-sm-0 label-sm-hide border-bottom py-2 py-sm-1">
            <Col sm={4} className="my-1">
                <Label className="form-field-title">Invoice No</Label>
                <Control value={details.invoice_no} readOnly />
            </Col>
            <Col sm={4} className="my-1">
                <Label className="form-field-title">Balance</Label>
                <Control type="text" value={currency(details.balance).format()} readOnly />
            </Col>
            <Col sm={4} className="my-1">
                <Label className="form-field-title">Payment Amount</Label>
                <Group>
                    <Control
                        type="number"
                        step="0.01"
                        placeholder="e.g. 3453.68"
                        value={details.amount}
                        onChange={e => onChange({ ...details, amount: e.currentTarget.value })}
                        max={details.balance}
                        required
                    />
                    <Group.Append>
                        <Button variant="outline-danger" className="border-0" onClick={onDelete}>
                            <i className="fas fa-times" />
                        </Button>
                    </Group.Append>
                    <Control.Feedback type="invalid">
                        Payment against invoice must be greater than 1 and a maximum value of {currency(details.balance).format()}.
                    </Control.Feedback>
                </Group>
            </Col>
        </Row>

    )
}

/** 
 * @typedef {{
 *  title: string, 
 *  description: string
 *  qty: number,
 *  rate: number   
 *  tax: number
 * }} ExpenseItemObject
*/

/**
 * 
 * @param {{
 * expenseitems: {
 *      items: import('../resources/api/expenses').ExpensetItemObject[], 
 *      setItems: React.Dispatch<React.SetStateAction<import('../resources/api/expenses').ExpensetItemObject[]>>}
 * deleteditems: {
 *      deleted_items: string[], 
 *      setDeletedItems: React.Dispatch<React.SetStateAction<string[]>>}
 * isTaxInclusive: boolean
 * children: React.ReactNode
 * }} param0 
 */
const ExpenseItems = ({ expenseitems, isTaxInclusive = true, children, deleteditems }) => {

    const { items, setItems } = expenseitems;
    const { setDeletedItems } = deleteditems;

    /**
     * add a new all empty invoice item.
     */
    const addItem = useCallback(() => {
        setItems(items => items.concat({
            category: '',
            notes: '',
            quantity: '',
            unit_price: '',
            tax_percent: 0,
            isNew: true
        }));
    }, [setItems])

    /**
     * UPdate an item in the list
     * @param {number} index 
     * @param {ExpenseItemObject} value 
     */
    const updateItem = (index, value) => {

        // console.log(value);
        let current_items = [...items];
        value.hasChanged = true;
        current_items.splice(index, 1, value);
        setItems(current_items);
    }

    /**
     * Deletes an item in poisition index from the list.
     * @param {number} index Index of item in the list
     */
    const deleteItem = index => {
        if (items.length < 2) return errorAlert("You must have at least one expense item on the expense if expense is itemized.");
        const _item = { ...items[index] };

        if (_item.id) setDeletedItems(items => items.concat(_item.id));

        setItems(items.filter((e, i) => i !== index));
    }

    /**
     * 
     */
    const totals = useMemo(() => {

        if (items.length === 0) return {
            tax: 0,
            total: 0
        };

        let tax, sub, total, _tax = [], _total = [];

        items.forEach(i => {
            if (!(i.unit_price && i.quantity)) return;

            total = i.quantity * i.unit_price;

            if (!i.tax_percent) {
                _total.push(total);
                return;
            }

            if (isTaxInclusive) {
                sub = total / (1 + parseFloat(i.tax_percent) / 100);
                _tax.push(total - sub);
                _total.push(total);
            } else {
                tax = parseFloat(i.tax_percent) / 100 * total;
                _tax.push(tax);
                _total.push(tax + total);
            }
        })

        /** @type {number} */
        const __total = _total.reduce((p, t) => p + t, 0);

        /** @type {number} */
        const __tax = _tax.reduce((a, t) => a + t, 0);

        return {
            tax: __tax,
            total: __total
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(items), isTaxInclusive])

    const style = {
        minWidth: '175px',
        textAlign: 'right',
        display: 'inline-block'
    }

    useEffect(() => {
        if (items.length === 0) addItem();
    }, [items.length, addItem])

    return (
        <>
            <Row className="d-none d-sm-flex g-2 text-center">
                <Col sm={3} className="py-0">
                    <Form.Label className="form-field-title">Category</Form.Label>
                </Col>
                <Col sm={4} className="py-0">
                    <Form.Label className="form-field-title">Notes</Form.Label>
                </Col>
                <Col sm={1} className="py-0">
                    <Form.Label className="form-field-title">Qty</Form.Label>
                </Col>
                <Col sm={2} className="py-0">
                    <Form.Label className="form-field-title">Unit Price</Form.Label>
                </Col>
                <Col sm={2} className="py-0">
                    <Form.Label className="form-field-title">Tax</Form.Label>
                </Col>
            </Row>
            {items.map((e, i) => <ExpenseItem key={i} details={e} onChange={value => updateItem(i, value)} onDelete={() => deleteItem(i)} />)}
            <Row>
                <Col sm={12} className="mt-3 mb-2 d-flex flex-column-reverse flex-sm-row justify-content-between align-items-center">
                    <div>
                        <Button variant="secondary" size="sm" className="rounded-pill m-1" onClick={addItem}>
                            <i className="fas fa-plus-circle me-2" />Add Item
                        </Button>
                        {children}
                    </div>

                    <div className="text-end">
                        {totals.tax > 0 &&
                            <>
                                Tax: UGX <span style={style} className="font-weight-normal h5 my-0">{currency(totals.tax).format()}</span> <br /></>
                        }
                        Expense Total: UGX <span style={style} className="font-weight-bold h4 my-0">{currency(totals.total).format()}</span>
                    </div>

                </Col>
            </Row>
        </>
    )
}

/**
 * 
 * @param {{
 * details: import('../resources/api/expenses').ExpensetItemObject,
 * onChange: (details: import('../resources/api/expenses').ExpensetItemObject) => void
 * onDelete: () => void
 * }} props 
 */
const ExpenseItem = ({ details, onChange, onDelete }) => {

    const { Label, Control } = Form;

    return (
        <div className="my-3 my-sm-0 py-1 py-sm-0 border-start border-primary label-sm-hide" style={{ borderLeftWidth: '3px!important' }}>
            <Row className='g-2'>
                <Col sm={3} className="my-1">
                    <Label className="form-field-title">Category</Label>
                    <Form.Select
                        size="sm"
                        value={details.category}
                        onChange={e => onChange(({ ...details, category: e.currentTarget.value }))}
                        required
                    >
                        <option value=""></option>
                        {allExpenseCategories.map(c => <option key={c} value={c}>{UCWords(c.replace(/-/g, " "))}</option>)}
                    </Form.Select>
                    <Control.Feedback type="invalid">
                        Select a category account for this expense line.
                    </Control.Feedback>
                </Col>
                <Col sm={4} className="my-1">
                    <Label className="form-field-title">Notes</Label>
                    <Control
                        as="textarea"
                        size="sm"
                        value={details.notes}
                        onChange={e => onChange(({ ...details, notes: e.currentTarget.value }))}
                        rows={1}
                        onFocus={e => e.currentTarget.rows = 3}
                        onBlur={e => e.currentTarget.rows = 1}
                        required
                    />
                    <Control.Feedback type="invalid">
                        Item must have a title.
                    </Control.Feedback>
                </Col>
                <Col xs={3} sm={1} className="my-1">
                    <Label className="form-field-title">Quantity</Label>
                    <Control
                        size="sm"
                        value={details.quantity}
                        onChange={e => onChange(({ ...details, quantity: e.currentTarget.value }))}
                        type="number"
                        required
                        min="1"
                    />
                    <Control.Feedback type="invalid">
                        Quantity must not be less than 1.
                    </Control.Feedback>
                </Col>
                <Col xs={5} sm={2} className="my-1">
                    <Label className="form-field-title">Unit Price</Label>
                    <Control
                        size="sm"
                        value={details.unit_price}
                        onChange={e => onChange(({ ...details, unit_price: e.currentTarget.value }))}
                        type="number"
                        step="0.01"
                        required
                        min="0.01"
                    />
                    <Control.Feedback type="invalid">
                        Unit price must not be less than 0.01.
                    </Control.Feedback>
                </Col>
                <Col xs={4} sm={2} className="my-1">
                    <Label className="form-field-title">Tax</Label>
                    <InputGroup>
                        <Form.Select
                            size="sm"
                            as="select"
                            value={details.tax_percent}
                            onChange={e => onChange(({ ...details, tax_percent: e.currentTarget.value }))}
                        >
                            <option value="0"></option>
                            {taxes.map(t => <option key={t.title} value={t.value}>{t.title}</option>)}
                        </Form.Select>
                        <Button onClick={onDelete} size="sm" variant="link" className="text-danger" >
                            <i className="fas fa-times" />
                        </Button>
                        <Control.Feedback type="invalid">
                            Unit price must not be less than 0.01.
                        </Control.Feedback>
                    </InputGroup>
                </Col>
            </Row>
        </div>
    )
}



/**
 * 
 * @param {{
* requisitionitems: {
    *      items: import('../resources/api/requisitions').RequisitionItemObject[], 
    *      setItems: React.Dispatch<React.SetStateAction<import('../resources/api/requisitions').RequisitionItemObject[]>>}
    * deleteditems: {
    *      deleted_items: string[], 
    *      setDeletedItems: React.Dispatch<React.SetStateAction<string[]>>}
    * children: React.ReactNode
    * }} param0 
    */
const RequisitionItems = ({ requisitionitems, children, deleteditems }) => {

    const { items, setItems } = requisitionitems;
    const { setDeletedItems } = deleteditems;

    /**
     * add a new all empty invoice item.
     */
    const addItem = useCallback(() => {
        setItems(items => items.concat({
            category: "",
            notes: '',
            quantity: '',
            unit_price: '',
            isNew: true
        }));
    }, [setItems])

    /**
     * update an item in the list
     * @param {number} index 
     * @param {ExpenseItemObject} value 
     */
    const updateItem = (index, value) => {

        // console.log(value);
        let current_items = [...items];
        value.hasChanged = true;
        current_items.splice(index, 1, value);
        setItems(current_items);
    }

    /**
     * Deletes an item in poisition index from the list.
     * @param {number} index Index of item in the list
     */
    const deleteItem = index => {
        if (items.length < 2) return errorAlert("You must have at least one requisition item on the expense if expense is itemized.");
        const _item = { ...items[index] };

        if (_item.id) setDeletedItems(items => items.concat(_item.id));

        setItems(items.filter((e, i) => i !== index));
    }

    /**
     * 
     */
    const totals = useMemo(() => {

        if (items.length === 0) return {
            tax: 0,
            total: 0
        };

        let total, _total = [];

        items.forEach(i => {
            if (!(i.unit_price && i.quantity)) return;

            total = i.quantity * i.unit_price;
            _total.push(total);
        })

        /** @type {number} */
        const __total = _total.reduce((p, t) => p + t, 0);

        return {
            total: __total
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(items)])

    const style = {
        minWidth: '175px',
        textAlign: 'right',
        display: 'inline-block'
    }

    useEffect(() => {
        if (items.length === 0) addItem();
    }, [items.length, addItem])

    return (
        <>
            <Row className="d-none d-sm-flex g-2">
                <Col sm={3} className="py-0">
                    <Form.Label className="form-field-title">Category</Form.Label>
                </Col>
                <Col sm={4} className="py-0">
                    <Form.Label className="form-field-title">Notes</Form.Label>
                </Col>
                <Col sm={2} className="py-0">
                    <Form.Label className="form-field-title">Quantity</Form.Label>
                </Col>
                <Col sm={3} className="py-0">
                    <Form.Label className="form-field-title">Unit Price</Form.Label>
                </Col>
            </Row>
            {items.map((e, i) => <RequisitionItem key={i} details={e} onChange={value => updateItem(i, value)} onDelete={() => deleteItem(i)} />)}
            <Row>
                <Col sm={12} className="mt-3 mb-2 d-flex flex-column-reverse flex-sm-row justify-content-between align-items-center">
                    <div>
                        <Button variant="secondary" size="sm" className="rounded-pill m-1" onClick={addItem}>
                            <i className="fas fa-plus-circle me-2" />Add Item
                        </Button>
                        {children}
                    </div>

                    <div className="text-end">
                        Requisition Total: UGX <span style={style} className="font-weight-bold h4 my-0">{currency(totals.total).format()}</span>
                    </div>

                </Col>
            </Row>
        </>
    )
}

/**
 * 
 * @param {{
 * details: import('../resources/api/requisitions').RequisitionItemObject,
 * onChange: (details: import('../resources/api/requisitions').RequisitionItemObject) => void
 * onDelete: () => void
 * }} props 
 */
const RequisitionItem = ({ details, onChange, onDelete }) => {

    const { Label, Control } = Form;

    return (
        <div className="my-3 py-2 py-sm-0 my-sm-0 border-start border-primary label-sm-hide" style={{ borderLeftWidth: '3px!important' }}>
            <Row className='g-2'>
                <Col sm={3} className="my-1">
                    <Label className="form-field-title">Category</Label>
                    <FormSelect
                        size="sm"
                        value={details.category}
                        onChange={e => onChange(({ ...details, category: e.currentTarget.value }))}
                        required
                    >
                        <option value=""></option>
                        {expenseCategories.map(c => <option value={c} key={c}>{UCWords(c.replace(/-/g, " "))}</option>)}
                    </FormSelect>
                    <Control.Feedback type="invalid">
                        You must choose an expense account
                    </Control.Feedback>
                </Col>
                <Col sm={4} className="my-1">
                    <Label className="form-field-title">Notes</Label>
                    <Control
                        size="sm"
                        as="textarea"
                        placeholder=""
                        value={details.notes}
                        onChange={e => onChange(({ ...details, notes: e.currentTarget.value }))}
                        rows={1}
                        onFocus={e => e.currentTarget.rows = 5}
                        onBlur={e => e.currentTarget.rows = 2}
                        required
                        maxLength={150}
                    />
                    <Control.Feedback type="invalid">
                        Describe the item. Max No Characters: 150. No Characters: {details.notes.length}
                    </Control.Feedback>
                </Col>
                <Col xs={5} sm={2} className="my-1">
                    <Label className="form-field-title">Quantity</Label>
                    <Control
                        size="sm"
                        value={details.quantity}
                        onChange={e => onChange(({ ...details, quantity: e.currentTarget.value }))}
                        // placeholder="e.g., 1"
                        type="number"
                        required
                        min="1"
                    />
                    <Control.Feedback type="invalid">
                        Quantity must not be less than 1.
                    </Control.Feedback>
                </Col>
                <Col xs={7} sm={3} className="my-1">
                    <Label className="form-field-title">Unit Price</Label>
                    <InputGroup>
                        <Control
                            size="sm"
                            value={details.unit_price}
                            onChange={e => onChange(({ ...details, unit_price: e.currentTarget.value }))}
                            // placeholder="e.g., 200.00"
                            type="number"
                            step="0.01"
                            required
                            min="0.01"
                        />
                        <Button onClick={onDelete} size="sm" variant="link" className="text-danger" >
                            <i className="fas fa-times" />
                        </Button>
                        <Control.Feedback type="invalid">
                            Unit price must not be less than 0.01.
                        </Control.Feedback>
                    </InputGroup>
                </Col>
            </Row>
        </div>
    )
}





export {
    InvoiceNotes,
    InvoiceTCs,
    DisplayTotals,
    DisplayInvoiceItems,
    DisplayInvoiceNotes,
    DisplayInvoicePayments,
    PaymentItems,
    ExpenseItems,
    RequisitionItems
};

export default InvoiceItems;